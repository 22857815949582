@import "../../scss/variables";

.videoSpotlight {
    line-height: 0;
    overflow: hidden;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &.heightAdjusted {
        width: 100%;

        .backgroundVideo {
            width: 100%;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
        }

        .backgroundVideoSafari {
            width: 100%;
            position: relative;
            top: 50%;
            transform: translateY(0%);
        }
    }

    .backgroundVideo {
        height: auto;
        width: 100%;
    }

    .backgroundVideoSafari {
        height: auto;
        width: 100%;
    }

    #overlay {
        position: absolute; 
        top: 42%; 
        left: 12%;
        right: 17%;
        color: #FFF;
        z-index: 2;
        margin-left: auto;
        margin-right: auto;
        display: flex;    
        text-align: center;
        white-space: nowrap;
        justify-content:center;
        font-size:4em;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 20px;
        letter-spacing:2px;
        opacity: 1;
        align-items:center;
        display: block;
        animation: fadeIn 1s;
 
        .fade{
            animation: fadeOut 1s;
        }
        
      }

    .btnPlayPause {
        top: 20px;
        position: absolute;
        z-index: 1;
        left: 20px;
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
        width: 20px;
        border: 1px solid transparent;
        background: rgba(#000000, .65);

        &::after,
        &::before {
            color: $white;
            cursor: pointer;
            font-family: $fontAwesome;
            font-size: 18px;
            position: absolute;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            z-index: 2;
        }
        &::after {
            content: "\f04c";
        }
        &::before {
            content: "\f04b";
        }
    }

    &[class*="isPlaying"] {
        .btnPlayPause {
              &::after {
                opacity: 1;
            }  
        }
    }
    &:not([class*="isPlaying"]) {
        .btnPlayPause {
            &::before {
                opacity: 1;
            }
        }
    }

    @media (min-width: 1366px) {
        &.heightAdjusted {
            min-height: 610px;
        }
        #overlay{
            font-size: 4em;
        }
    }
    @media (max-width: $desktop-smaller) {
        #overlay{
            font-size: 3.5em;
        }
    }
    @media (max-width: $mobile-larger) {
        &.heightAdjusted {
            max-height: 390px;
        }
        #overlay{
            font-size: 2.5em;
        }
    }
    @media (max-width: $mobile) {
        &.heightAdjusted {
            max-height: 285px;
        }
        #overlay{
            font-size: 1.7em;
        }
    }
    @media (max-width: $mobile-smaller) {
        &.heightAdjusted {
            max-height: 185px;
        }
        #overlay{
            font-size: 1.1em;
            left: 10%;
        }
    }
    @media (max-width: 280px) {
        &.heightAdjusted {
            max-height: 185px;
        }
        #overlay{
            font-size: 0.7em;
            left: 10%;
        }
    }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
}


.spotlightImg {
    width: 100%;
    height: auto;
    object-fit: cover;
    // object-position: top;
    transition-duration: 1000ms;
}
.fadeInImage{
    animation: fadeIn 1s;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@media screen and (min-width: 3000px) {
    .spotlightImg {
        min-height: 1080px;
    }
}
@media screen and (max-width: 3000px) {
    .spotlightImg {
        min-height: 980px;
    }
}
@media screen and (max-width: 2500px) {
    .spotlightImg {
        min-height: 780px;
    }
}
@media screen and (max-width: 1950px) {
    .spotlightImg {
        min-height: 580px;
    }
}

@media screen and (max-width: 1449px) {
    .spotlightImg {
        min-height: 484px;
    }
}

//colour themes for overlay text-shadow
.mediumGrayTheme{
    text-shadow: 0px 1px 11px #555555,
         0px 1px 20px #555555,
         0px 1px 20px #555555;
         
}

.darkGrayTheme{
    text-shadow: 0px 1px 20px #333333,
         0px 1px 20px #333333,
         0px 1px 20px #333333;
         
}

.darkestGrayTheme{
    text-shadow: 0px 1px 11px #111111,
         0px 1px 20px #111111,
         0px 1px 20px #111111;
         
}